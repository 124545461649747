<template>
  <div>
    <b-button
      type="button"
      variant="info"
      class="ml-3"
      @click="getContract()"
      :disabled="isLoading"
    >
      <span v-if="!isLoading">Фидуцийн гэрээ</span>
      <span v-else>Уншиж байна</span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "contract-fidutiary",
  data: function() {
    return {
      isLoading: false,
    };
  },
  props: {
    selectCustomer: { type: [Array, Object], default: () => [] },
    deposit: { type: [Array, Object], default: () => [] },
    contract_number: { type: String, default: "" },
    amount: { type: Number, default: 0 },
  },
  methods: {
    getContract: function() {
      this.$data.isLoading = true;

      this.$http
        .post(
          this.$config.API_URL +
            "ContractWebService/auto_machine_fiduciary_contract",
          {
            data: JSON.stringify({
              contractNumber: this.contract_number,
              customerId: this.selectCustomer.id,
              loanAmount: this.amount,
              depositMark: this.deposit.mark,
              depositCountryNumber: this.deposit.countryCode,
              depositFactoryYear: this.deposit.factoryYear,
              depositIncomeDate: this.deposit.incomeYear,
              depositNumber: this.deposit.number,
              depositSerialNumber: this.deposit.registrationCode,
              depositIntended: this.deposit.intended,
              depositColor: this.deposit.color,
              depositEvaluationAmount: this.deposit.insuranceEvaluationAmount,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.$data.isLoading = false;
            console.log(response);
            if (response.body.successData) {
              window.open(response.body.successData, "_blank");
            } else {
              this.showToast("Анхаар", "Алдаа гарсан байна", "warning");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
